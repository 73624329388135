<template></template>

<script>
import { loginByCode } from "../services/user";
import { setMvpToken, setToken } from "../utils/auth";

export default {
  name: "mvpLogin2",
  mounted() {
    if (this.$route.query.code) {
      loginByCode(this.$route.query.code).then(res => {
        setMvpToken(res.mvpToken)
        setToken(res.token)
        this.$router.push('/')
      })
    } else {
      this.$router.push('/mvpLogin')
    }
  },
};
</script>

<style scoped></style>
